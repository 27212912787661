$(document).ready(function() 

{
    

$('#btn-submit-signin').click(function(e){
    e.preventDefault();

    var user = $('#login-user').val();
    var pass = $('#login-pass').val();

    var logindata = {
        'username': user,
        'password': pass,
        'remember-me': $('#remember-me').is(':checked') ? true : false
    };
  
    $.ajax({
        url: '/login',
        data: logindata,
        dataType: 'json',
        type: 'POST',
        beforeSend: function() {
            $('#message-login').addClass('hide');
        },
        success: function(data) {

            // entrar en la pantalla de administracion
           console.log("success data:"+JSON.stringify(data))
       
        if(origin=='admin')
            document.location.href = '/admin/'+id;
            // live org
       if (origin=='org')
       document.location.href = '/org/'+slug;
    
        },
        error: function() {
            $('#message-login').removeClass('hide');
        }
    });


});

});

/*
$(document).on('traky_init', function() {
 

    $(document).on('click', '#submit-recover', function(e) {
    
        e.preventDefault();
        traky.rememberMe(e);
    });

    traky.login = function(e) {
      
        e.preventDefault();

        var user = $('#login-user').val();
        var pass = $('#login-pass').val();

        var logindata = {
            'username': user,
            'password': pass,
            'remember-me': $('#remember-me').is(':checked') ? true : false
        };
      
        $.ajax({
            url: '/login',
            data: logindata,
            dataType: 'json',
            type: 'POST',
            beforeSend: function() {
                $('#message-login').addClass('hide');
            },
            success: function(data) {
              
                document.location.href = '/';

            },
            error: function() {
                $('#message-login').removeClass('hide');
            }
        });
    };

    traky.rememberMe = function(e) {
        var email = $('#input-recover').val();
        if (_.isEmail(email)) {
            $.ajax({
                data: {email: email},
                url: '/accounts/lostpassword',
                type: 'post',
                dataType: 'json',
                beforeSend: function() {
                    $('#message-remember-error').addClass('hide');
                },
                success: function(data) {
                    $('#form-recover').hide();
                    $('#form-recover').remove();

                    $('#message-remember-error').addClass('hide');
                    $('#message-remember-info').addClass('hide');
                    $('#message-remember-ok').removeClass('hide');

                },
                error: function(xhr) {

                    $('#message-remember-error').removeClass('hide');
                   
                }
            });
        } else {
            $('#message-remember-error').removeClass('hide');
        }
    };

    traky.logout = function(e) {
   
        e.stopPropagation();
        $.removeCookie('traky.sessionkey');
        document.location.href = '/logout';
    };

    traky.loggedIn = function() {
        // return $.cookie('traky.sessionkey');
       

        return true;
    };

    traky.checkLogin = function() {
   
        if (!traky.loggedIn()) {
            document.location.href = '/login';
            return false;
        }
       
        return true;
    };

});
*/